import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";

export const AppContext = createContext({
	app: null,
	loading: true,
	getApp: () => {},
});

export const AppProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const [app, setApp] = useState(null);
	const [loading, setLoading] = useState(true);

	const getApp = useCallback(async () => {
		setLoading(true);

		await makeRequest({
			endPoint:
				"global?populate[0]=footer.background_image&populate[1]=header&populate[2]=cookie_policy&populate[3]=lets_talk&populate[4]=page_404&populate[5]=privacy_policy&populate[6]=term_and_conditions&populate[7]=social",
			// "global?populate=*",
			params: {
				locale: i18n.language,
			},
		}).then((resp) => {
			const attributes = resp?.data?.attributes;

			setApp(attributes);
			setLoading(false);
		});
	}, [i18n.language]);

	useEffect(() => {
		// console.log("APP LOADED", app);
		getApp(); // loading it here cause double call - not sure why
	}, []);

	useEffect(() => {
		// if (app) console.log("APP:", app);
	}, [app]);

	return (
		<AppContext.Provider
			value={{
				app,
				loading,
				getApp,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
