import React, { useContext } from "react";
import { WorksContext, WorksProvider } from "@src/context/works-context";

const useWorks = () => {
	const context = useContext(WorksContext);

	return context;
};

export { WorksProvider, useWorks };
