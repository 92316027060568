import React from "react";
import { AppProvider } from "@src/hooks/use-app";
import { WorksProvider } from "@src/hooks/use-works";
import { I18nextProvider } from "react-i18next";
import i18next from "@src/i18n/config";
import { TeamsProvider } from "@src/hooks/use-teams";
import { InsightsProvider } from "@src/hooks/use-insights";
import { JobsProvider } from "@src/hooks/use-jobs";

const RootElement = ({ children }) => {
	return (
		<I18nextProvider i18n={i18next}>
			<AppProvider>
				<WorksProvider>
					<TeamsProvider>
						<InsightsProvider>
							<JobsProvider>{children}</JobsProvider>
						</InsightsProvider>
					</TeamsProvider>
				</WorksProvider>
			</AppProvider>
		</I18nextProvider>
	);
};

export default RootElement;
