import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";

export const InsightsContext = createContext({
	insights: null,
	loading: false,
	getInsights: () => {},
});

export const InsightsProvider = ({ children, data }) => {
	const { i18n } = useTranslation();
	const [insights, setInsights] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [filtering, setFiltering] = useState(false);
	const [page, setPage] = useState(0);
	const [urlString, setUrlString] = useState("");

	const pageSize = 15;
	const api = process.env.STRAPI_API_URL + "/api/";

	const getInsights = useCallback(
		async (params = {}, stringParam = "", load = true) => {
			setLoading(true);

			let queryParam = "";
			if (urlString) {
				queryParam = `${api}news-and-insights?${urlString}`;
			}
			if (stringParam) {
				queryParam = `${api}news-and-insights?${stringParam}`;
				setUrlString(stringParam);
			} else {
				setUrlString("");
			}

			let paramsData = {
				locale: i18n.language,
				"pagination[pageSize]": pageSize,
				"pagination[page]": page === 0 ? 1 : page,
				"populate[image]": "image",
				"populate[tags][fields][0]": "name",
				"populate[sectors][fields][0]": "name",
				"populate[solutions][fields][0]": "name",
				"populate[article_types][fields][0]": "name",
				sort: "date:desc",
				...params,
			};

			// console.log("HASLIMIT", params.hasOwnProperty("pagination[limit]"));

			if (paramsData && paramsData.hasOwnProperty("pagination[limit]")) {
				delete paramsData["pagination[page]"];
				delete paramsData["pagination[pageSize]"];
			}

			await makeRequest({
				endPoint: "news-and-insights",
				params: paramsData,
				urlString: queryParam,
			}).then((resp) => {
				// If you want to always reset
				// if (stringParam || page <= 1) {
				setInsights(resp);
				// } else {
				// 	// Retain data
				// 	let respData = {};
				// 	let insightsData = [];
				// 	if (insights && insights.hasOwnProperty("data")) {
				// 		insightsData = insights.data;
				// 	}
				// 	if (resp?.data) {
				// 		insightsData = [...insightsData, ...resp?.data];
				// 		respData = {
				// 			data: insightsData,
				// 			meta: resp.meta,
				// 		};
				// 	}
				// 	setInsights(respData);
				// }
				setLoading(false);
			});
		},
		[i18n.language, page, urlString]
	);

	useEffect(() => {
		// console.log("APP LOADED", insights);
		// getInsights(); // do not load immediately
		setInitialLoad(false);
	}, []);

	useEffect(() => {
		// if (insights) console.log("InsightsContext:", insights);
		if (!initialLoad) {
			if (!filtering && page > 1) {
				setTimeout(() => {
					window.scrollTo({ top: 0, behavior: "smooth" });
				}, 100);
			}
		}
	}, [insights]);

	useEffect(() => {
		// console.log(page);
		if (page > 0) {
			if (filtering && urlString) {
				getInsights({}, urlString);
			} else {
				getInsights();
			}
		}
	}, [page]);

	useEffect(() => {
		if (!initialLoad) {
			if (filtering) {
				// getInsights({}, urlString);
			} else {
				getInsights();
			}
		}
	}, [filtering]);

	useEffect(() => {
		if (!initialLoad) {
			// console.log(urlString);
			getInsights({}, urlString);
		}
	}, [urlString]);

	return (
		<InsightsContext.Provider
			value={{
				insights,
				loading,
				getInsights,
				page,
				setPage,
				pageSize,
				urlString,
				setUrlString,
				filtering,
				setFiltering,
			}}
		>
			{children}
		</InsightsContext.Provider>
	);
};
