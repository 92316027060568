import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";

export const JobsContext = createContext({
	jobs: null,
	loading: false,
	getJobs: () => {},
});

export const JobsProvider = ({ children, data }) => {
	const { i18n } = useTranslation();
	const [jobs, setJobs] = useState(null);
	const [loading, setLoading] = useState(false);

	const getJobs = useCallback(
		async (params = {}) => {
			setLoading(true);

			await makeRequest({
				endPoint: "jobs",
				params: {
					locale: i18n.language,
					sort: "date:desc",
					...params,
				},
			}).then((resp) => {
				setJobs(resp);
				setLoading(false);
			});
		},
		[i18n.language]
	);

	useEffect(() => {
		// console.log("APP LOADED", jobs);
		// getJobs(); // do not load immediately
	}, []);

	useEffect(() => {
		// if (jobs) console.log("JobsContext:", jobs);
	}, [jobs]);

	return (
		<JobsContext.Provider
			value={{
				jobs,
				loading,
				getJobs,
			}}
		>
			{children}
		</JobsContext.Provider>
	);
};
