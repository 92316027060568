import React, { useContext } from "react";
import { TeamsContext, TeamsProvider } from "@src/context/teams-context";

const useTeams = () => {
	const context = useContext(TeamsContext);

	return context;
};

export { TeamsProvider, useTeams };
