import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";

export const TeamsContext = createContext({
	teams: null,
	loading: false,
	getTeams: () => {},
});

export const TeamsProvider = ({ children, data }) => {
	const { i18n } = useTranslation();
	const [teams, setTeams] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [filtering, setFiltering] = useState(false);
	const [page, setPage] = useState(0);
	const [urlString, setUrlString] = useState("");

	const pageSize = 18;
	const api = process.env.STRAPI_API_URL + "/api/";

	const getTeams = useCallback(
		async (params = {}, stringParam = "", load = true) => {
			if (load) {
				setLoading(true);
			}

			let queryParam = "";
			if (stringParam) {
				queryParam = `${api}teams?${stringParam}`;
				setUrlString(stringParam);
			} else {
				setUrlString("");
			}

			await makeRequest({
				endPoint: "teams",
				params: {
					locale: i18n.language,
					"pagination[pageSize]": pageSize,
					"pagination[page]": page === 0 ? 1 : page,
					"populate[image]": "*",
					sort: "display_order:asc",
					...params,
				},
				urlString: queryParam,
			}).then((resp) => {
				setTeams(resp);
				// If you want to always reset
				if (stringParam || page <= 1) {
					setTeams(resp);
				} else {
					// Retain data
					let respData = {};
					let teamsData = [];
					if (teams && teams.hasOwnProperty("data")) {
						teamsData = teams.data;
					}
					if (resp?.data) {
						teamsData = [...teamsData, ...resp?.data];
						respData = {
							data: teamsData,
							meta: resp.meta,
						};
					}
					setTeams(respData);
				}
				if (load) {
					setLoading(false);
				}
			});
		},
		[i18n.language, page, urlString]
	);

	useEffect(() => {
		// console.log("APP LOADED", teams);
		// getTeams(); // do not load immediately
		setInitialLoad(false);
	}, []);

	useEffect(() => {
		// if (teams) console.log("TeamsContext:", teams);
	}, [teams]);

	useEffect(() => {
		// console.log(page);
		if (page > 0) {
			getTeams();
		}
	}, [page]);

	useEffect(() => {
		if (!initialLoad) {
			if (filtering) {
				getTeams({}, urlString);
			} else {
				getTeams();
			}
		}
	}, [filtering]);

	return (
		<TeamsContext.Provider
			value={{
				teams,
				loading,
				getTeams,
				page,
				setPage,
				pageSize,
				urlString,
				setUrlString,
				filtering,
				setFiltering,
			}}
		>
			{children}
		</TeamsContext.Provider>
	);
};
