import React, { useContext } from "react";
import {
	InsightsContext,
	InsightsProvider,
} from "@src/context/insights-context";

const useInsights = () => {
	const context = useContext(InsightsContext);

	return context;
};

export { InsightsProvider, useInsights };
