import React, { useContext } from "react";
import { JobsContext, JobsProvider } from "@src/context/jobs-context";

const useJobs = () => {
	const context = useContext(JobsContext);

	return context;
};

export { JobsProvider, useJobs };
